<template>
  <section id="notfound" class="panel">
    <div class="inset-content">
      <div id="not-found-description">
        <div>
          <h1>Page Not Found</h1>
        </div>
      </div>
    </div>
  </section>
</template>
<script></script>
>
<style scoped>
#not-found-description {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 10vw;
  font-size: 2.2vmin;
}
</style>
>
